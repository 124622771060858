<template>
  <div class="flex flex-col w-full">
    <template v-if="component">
      <div class="relative flex flex-1 bg-neutral-gray">
        <component
          :is="component"
          :key="sidebarKey"
          v-bind="props"
          @close="closeAndEmit"
        />
      </div>
    </template>
    <template v-else>
      <div class="relative flex flex-1 bg-neutral-gray">
        <div class="flex-1">
          <div class="bg-gray-700">
            <ViewerBody :gui="gui" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as api from '@utils/api.js'
const SceneBaseSetup = () => import('@modals/SceneBaseSetup')
const ViewerBody = () => import('@components/editor/scene/viewer/ViewerBody')
const SceneTree = () => import('@components/editor/scene/panels/SceneTree')

export default {
  name: 'ItemInspector',

  components: {
    ViewerBody,
    SceneTree
  },

  props: {
    viewer: {
      type: Object,
      required: true
    }
  },

  watch: {
    component: {
      handler() {
        this.$bus.$emit('editor:panel:toggle.inspector', !!this.component)
      },
      immediate: true
    }
  },

  data() {
    return {
      active: 'viewer',
      module: false,
      component: null,
      props: null,
      activeNodeId: null
    }
  },

  computed: {

    assetId() {
      return this.$route.params.uuid
    },

    driveId() {
      return this.$route.query.drive
    },

    core() {
      return this.viewer.core
    },

    gui() {
      return this.core.gui
    },

    isSandbox() {
      return this.$route.meta.isSandbox === true
    }
  },

  mounted() {
    this.attachListeners()
  },

  methods: {
    attachListeners() {
      this.$bus.$on('scene:camera:setdefault', this.setDefaultCameraPos)
      this.$bus.$on('scene:camera:add', this.addCameraBookmark)
      this.$bus.$on('scene:model:import', this.importIntoScene)
      this.$bus.$on('scene:load', this.loadScene)
      this.$bus.$on('scene:model:require-full-save', this.requireFullSave)
      this.$bus.$on('bookmark:rename', this.onRenameBookmark)
      this.$bus.$on('bookmark:makedefault', this.onMakeBookmarkDefault)
      this.$bus.$on('bookmark:delete', this.onDeleteBookmark)
      this.$bus.$on('model:scenegraph:viewnode', this.onViewSceneGraphNode)
      this.$bus.$on('editor:inspector:clearactive', this.closeAndEmit)
      this.$bus.$on('editor:inspector:makeactive', this.onSelectedFromTree)
      this.$bus.$on('editor:sidebar:clear', (event) => {
        this.closeAndEmit()
      })
      this.$bus.$on('editor:sidebar', (event) => {
        this.showSidebar(event)
      })
    },

    closeAndEmit() {
      this.$bus.$emit('editor:inspector:makeactive')
      this.close()
    },

    close() {
      this.module = false
      this.component = null
      this.props = null
    },

    showSidebar(event) {
      this.active = 'model'
      this.component = null
      this.props = null
      this.module = false
      this.sidebarKey = null

      if (event.component !== undefined) {
        this.sidebarKey = Date.now()
        this.component = event.component

        if (event.props !== undefined) {
          this.props = event.props

          if (this.props.hasOwnProperty('key')) {
            this.sidebarKey = this.props.key
          }
        }
        this.module = true
        this.$bus.$emit('editor:inspector:makeactive', this.sidebarKey)
      } else {
        this.$bus.$emit('editor:inspector:clearactive')
      }
    },

    onViewSceneGraphNode(node) {
      this.active = 'model'
    },

    setDefaultCameraPos() {
      this.gui.newBookmark(true)
      this.$emit('settings-changed')
    },

    addCameraBookmark() {
      this.gui.newBookmark()
      this.$emit('settings-changed')
    },

    onRenameBookmark(event) {
      event.bookmark.name = event.name
      this.$emit('settings-changed')
    },

    onMakeBookmarkDefault(bookmark) {
      this.gui.makeBookmarkDefault(bookmark)
      this.$emit('settings-changed')
    },

    onDeleteBookmark(event) {
      this.gui.deleteBookmark(event.bookmark)
      this.$emit('settings-changed')
    },

    onSettingsChanged(event) {
      this.$emit('settings-changed')
    },

    onSelectedFromTree(id) {
      if (id === undefined) {
        return
      }

      let uuid = id.replace('node-', '')
      let selectedFromTree = this.gui.getObjectById(uuid)
      this.gui.onObjectSelected(selectedFromTree)
    },

    requireFullSave() {
      if (!this.isSandbox) {
        this.$bus.$emit('overlay:modal', {
          component: SceneBaseSetup,
          props: {
            gui: this.gui
          },
          size: 'md'
        })
      }
    },

    importIntoScene(obj) {
      // if (!this.isSandbox) {
      //   this.$bus.$emit('overlay:modal', {
      //     component: SceneBaseSetup,
      //     props: {
      //       file: file,
      //       gui: this.gui
      //     },
      //     size: 'md'
      //   })
      // } else {
      this.core.importIntoSequence(obj)
      // }
    },

    loadScene(data) {
      // Send the resources over to drive and save a demo content assets.
      const sequenceId = this.core.sequence.id // Make sure we keep the old sequence id, before loading a demo sequence
      const { sequence, resources } = data
      sequence.id = sequenceId // set back to the original id, so we can save this in the future
      this.core.loadSequence(sequence, resources)

      this.core.setHasChanges() // To force the changes to be flagged for autosave

      // @todo
      // Disabled for now, for Monday demos. Comeback and finish this.
      // this.storeDemoResources(resources)

      // Make sure we trigger an auto save after the import
    },

    storeDemoResources(resources) {
      resources.forEach(resource => {
        console.log('Upload this resource to drive, then adjust our local resource ids to match', resource)
        this.uploadToDriveViaUrl(resource).then(response => {
          // Remap resource id
          this.core.remapResourceIdToNewValue(resource.id, response.id)
        }).catch(err => {
          console.warning('Failed to copy resource to drive.', err)
        })
      })
    },

    uploadToDriveViaUrl(resource) {
        let driveApiUrl = process.env.VUE_APP_SERVER_API_BASE

        let payload = {
          drive_id: this.driveId,
          parent_id: this.assetId,
          name: resource.name,
          type: resource.type,
          extra: {
            resource: resource
          }
        }

        return new Promise((resolve, reject) => {
          api.rawApiPostCall(driveApiUrl + 'api/v1/copy-asset-to-library', payload)
          .then(response => {
            resolve(response.body.data)
          })
          .catch(err => {
            reject(err)
          })
        })
    }
  }
}
</script>
